import React, { useState, useEffect, useCallback } from 'react';
import { Container, Typography, Box } from '@mui/material';
import InstrumentForm from './components/InstrumentForm';
import InstrumentTable from './components/InstrumentTable';
import PayoffChart from './components/PayoffChart';
import DeltaChart from './components/DeltaChart';
import GammaChart from './components/GammaChart';
import VegaChart from './components/VegaChart';
import ThetaChart from './components/ThetaChart';
import StrategySelector from './components/StrategySelector';
import Introduction from './components/Introduction';
import ChartSelector from './components/ChartSelector'; // Import the new ChartSelector component
import { calculateTotalPayoff, calculatePremium, calculateIndividualPayoff } from './utils/instrumentCalculations';
import { getStrategy } from './utils/strategies';

function App() {
  const [instruments, setInstruments] = useState([]);
  const [spotPrice, setSpotPrice] = useState(100);
  const [riskFreeRate, setRiskFreeRate] = useState(5);
  const [volatility, setVolatility] = useState(20);
  const [payoffData, setPayoffData] = useState([]);
  const [selectedCharts, setSelectedCharts] = useState(['payoff']);



  const addInstrument = (newInstrument) => {
    if (newInstrument.type === 'option') {
      const premium = calculatePremium(
        newInstrument.optionType,
        newInstrument.strikePrice,
        spotPrice,
        newInstrument.maturityDate,
        riskFreeRate,
        volatility
      );
      setInstruments([...instruments, { ...newInstrument, premium }]);
    } else {
      setInstruments([...instruments, newInstrument]);
    }
  };

  const removeInstrument = (index) => {
    setInstruments(instruments.filter((_, i) => i !== index));
  };

  const updateInstrument = (index, updatedInstrument) => {
    const newInstruments = [...instruments];
    if (updatedInstrument.type === 'option') {
      const premium = calculatePremium(
        updatedInstrument.optionType,
        updatedInstrument.strikePrice,
        spotPrice,
        updatedInstrument.maturityDate,
        riskFreeRate,
        volatility
      );
      newInstruments[index] = { ...updatedInstrument, premium };
    } else {
      newInstruments[index] = updatedInstrument;
    }
    setInstruments(newInstruments);
  };

  const handleAddStrategy = (strategyName, customizations, keepExisting) => {
    const strategyInstruments = getStrategy(strategyName, spotPrice, customizations);
    if (strategyInstruments) {
      const newInstruments = strategyInstruments.map(instrument => {
        if (instrument.type === 'option') {
          return {
            ...instrument,
            premium: calculatePremium(
              instrument.optionType,
              instrument.strikePrice,
              spotPrice,
              instrument.maturityDate,
              riskFreeRate,
              volatility
            )
          };
        }
        return instrument;
      });

      if (keepExisting) {
        setInstruments([...instruments, ...newInstruments]);
      } else {
        setInstruments(newInstruments);
      }
    }
  };

  const calculatePayoffs = useCallback(() => {
    const individualPayoffs = instruments.map(instrument => 
      calculateIndividualPayoff(instrument, spotPrice, riskFreeRate, volatility)
    );
    const totalPayoff = calculateTotalPayoff(instruments, spotPrice, riskFreeRate, volatility);
    return [...individualPayoffs, totalPayoff];
  }, [instruments, spotPrice, riskFreeRate, volatility]);

  useEffect(() => {
    if (instruments.length > 0) {
      const newPayoffData = calculatePayoffs();
      setPayoffData(newPayoffData);
    } else {
      setPayoffData([]);
    }
  }, [instruments, spotPrice, riskFreeRate, volatility, calculatePayoffs]);
  const handleChartSelection = (event, newCharts) => {
    setSelectedCharts(newCharts);
  };

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h3" component="h1" gutterBottom>
        OptionsStrategiesviz - Options Strategy Visualization
        </Typography>
        <Box mb={1}>
          <Introduction />
        </Box>
        <Box mb={2}>
          <InstrumentForm 
            addInstrument={addInstrument} 
            spotPrice={spotPrice} 
            setSpotPrice={setSpotPrice}
            riskFreeRate={riskFreeRate}
            setRiskFreeRate={setRiskFreeRate}
            volatility={volatility}
            setVolatility={setVolatility}
            instrumentCount={instruments.length}
          />
        </Box>
        
        <Box mb={2}>
          <StrategySelector
            addStrategy={handleAddStrategy}
            spotPrice={spotPrice}
            hasExistingInstruments={instruments.length > 0}
          />
        </Box>
        
        <Box mb={2}>
          <InstrumentTable 
            instruments={instruments} 
            removeInstrument={removeInstrument} 
            updateInstrument={updateInstrument}
            spotPrice={spotPrice}
            riskFreeRate={riskFreeRate}
            volatility={volatility}
          />
        </Box>
        <Box mb={2}>
          <ChartSelector
            selectedCharts={selectedCharts}
            handleChartSelection={handleChartSelection}
          />
        </Box>
        
        {selectedCharts.includes('payoff') && (
          <Box mb={4}>
            <PayoffChart data={payoffData} instruments={instruments} />
          </Box>
        )}
        {selectedCharts.includes('delta') && (
          <Box mb={4}>
            <DeltaChart 
              instruments={instruments}
              spotPrice={spotPrice}
              riskFreeRate={riskFreeRate}
              volatility={volatility}
            />
          </Box>
        )}
        {selectedCharts.includes('gamma') && (
          <Box mb={4}>
            <GammaChart 
              instruments={instruments}
              spotPrice={spotPrice}
              riskFreeRate={riskFreeRate}
              volatility={volatility}
            />
          </Box>
        )}
        {selectedCharts.includes('vega') && (
          <Box mb={4}>
            <VegaChart 
              instruments={instruments}
              spotPrice={spotPrice}
              riskFreeRate={riskFreeRate}
              volatility={volatility}
            />
          </Box>
        )}
        {selectedCharts.includes('theta') && (
          <Box mb={4}>
            <ThetaChart 
              instruments={instruments}
              spotPrice={spotPrice}
              riskFreeRate={riskFreeRate}
              volatility={volatility}
            />
          </Box>
        )}
      </Box>
    </Container>
  );
}



export default App;