import React from 'react';
import { Line } from 'react-chartjs-2';
import { Typography, Tooltip, IconButton, Box } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend
);

function PayoffChart({ data, instruments }) {
  // Check if data is valid
  if (!data || data.length === 0 || !instruments || instruments.length === 0) {
    return <div>No data available for chart</div>;
  }

  const chartData = {
    labels: data[0] && data[0].length > 0 ? data[0].map(d => d.spotPrice.toFixed(2)) : [],
    datasets: [
      ...instruments.map((instrument, index) => {
        if (!data[index] || !Array.isArray(data[index])) return null;
        return {
          label: `${instrument.type === 'option' ? 
            `${instrument.optionType} ${instrument.position} (Strike: ${instrument.strikePrice})` : 
            `Stock ${instrument.position} (Shares: ${instrument.shares})`}`,
          data: data[index].map(d => d.payoff),
          borderColor: instrument.color || getRandomColor(),
          backgroundColor: instrument.color || getRandomColor(),
          tension: 0.1,
        };
      }).filter(Boolean),
      data[data.length - 1] && Array.isArray(data[data.length - 1]) ? {
        label: 'Total Payoff',
        data: data[data.length - 1].map(d => d.payoff),
        borderColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgb(0, 0, 0)',
        borderWidth: 2,
        tension: 0.1,
      } : null,
    ].filter(Boolean),
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Instrument Payoff Chart',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Stock Price at Expiration',
        },
        ticks: {
          maxTicksLimit: 10,
          autoSkip: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Payoff',
        },
      },
    },
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="h5" component="h2">Payoff Chart</Typography>
        <Tooltip title="This chart shows the potential profit or loss of the option strategy at different stock prices at expiration. The x-axis represents the stock price, and the y-axis shows the payoff (profit/loss).">
          <IconButton size="small">
            <HelpOutlineIcon fontSize="big" />
          </IconButton>
        </Tooltip>
      </Box>
      <div style={{ height: '50vh', width: '100%' }}>
        <Line options={chartOptions} data={chartData} />
      </div>
    </Box>
  );
}

function getRandomColor() {
  return '#' + Math.floor(Math.random()*16777215).toString(16);
}

export default PayoffChart;