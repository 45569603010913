import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Typography, Tooltip as MuiTooltip, IconButton, Box } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { calculateGreeks } from '../utils/greekCalculations';
import { differenceInDays } from 'date-fns';

const ThetaChart = ({ instruments, spotPrice, riskFreeRate, volatility }) => {
  const generateThetaData = () => {
    const today = new Date();
    const latestExpiry = new Date(Math.max(...instruments
      .filter(i => i.type === 'option')
      .map(i => new Date(i.maturityDate))));
    const daysTilExpiry = differenceInDays(latestExpiry, today);

    console.log(`Days till expiry: ${daysTilExpiry}`);

    return Array.from({ length: daysTilExpiry + 1 }, (_, i) => {
      const daysToExpiration = daysTilExpiry - i;
      const dataPoint = { daysToExpiration };

      instruments.forEach(instrument => {
        if (instrument.type === 'option') {
          const timeToMaturity = Math.max(daysToExpiration / 365, 1/365); // Minimum 1 day to expiry
          let { theta } = calculateGreeks(instrument, spotPrice, riskFreeRate, volatility, timeToMaturity);
          
          console.log(`Theta for ${instrument.optionType} ${instrument.strikePrice} at ${daysToExpiration} days to expiry: ${theta}`);

          // Adjust theta calculation for very short expiries
          if (daysToExpiration < 1) {
            theta *= daysToExpiration; // Linearly decrease theta to 0 on expiration day
          }
          
          if (instrument.position === 'short') {
            theta = -theta;
          }
          const key = `${instrument.optionType} ${instrument.strikePrice} (${instrument.position})`;
          dataPoint[key] = theta;
        }
      });

      return dataPoint;
    });
  };

  const data = generateThetaData();
  console.log('Theta Chart Data:', data);

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="h5" component="h2">Theta Chart</Typography>
        <MuiTooltip title="Theta measures the rate of change in the option's price with respect to the passage of time, assuming all other factors remain constant. It is typically negative for long options and positive for short options, indicating that options lose value as time passes.">
          <IconButton size="small">
            <HelpOutlineIcon fontSize="small" />
          </IconButton>
        </MuiTooltip>
      </Box>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="daysToExpiration" 
            type="number" 
            domain={[0, 'dataMax']}
            reversed={true}
            label={{ value: 'Days to Expiration', position: 'insideBottom', offset: -10 }}
          />
          <YAxis 
            label={{ value: 'Theta', angle: -90, position: 'insideLeft' }}
          />
          <Tooltip />
          <Legend />
          {instruments
            .filter(instrument => instrument.type === 'option')
            .map((instrument, index) => {
              const key = `${instrument.optionType} ${instrument.strikePrice} (${instrument.position})`;
              const quantity = instrument.quantity || 1;
              console.log(`Creating line for ${key}`);
              return (
                <Line
                  key={index}
                  type="monotone"
                  dataKey={key}
                  name={`${key} (Qty: ${quantity})`}
                  stroke={instrument.color}
                  strokeWidth={2}
                  dot={false}
                />
              );
            })
          }
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default ThetaChart;