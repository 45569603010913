import React, { useState, useEffect } from 'react';
import { TextField, Button, Select, MenuItem, Grid, FormControl, InputLabel, Paper, Typography, Tooltip, IconButton } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

// Predefined color palette
const colorPalette = [
  '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40',
  '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'
];

function InstrumentForm({ addInstrument, spotPrice, setSpotPrice, riskFreeRate, setRiskFreeRate, volatility, setVolatility, instrumentCount }) {
  const [instrumentType, setInstrumentType] = useState('option');
  const [optionType, setOptionType] = useState('call');
  const [strikePrice, setStrikePrice] = useState(spotPrice);
  const [position, setPosition] = useState('long');
  const [maturityDate, setMaturityDate] = useState(
    new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
  );
  const [shares, setShares] = useState(1);
  const [color, setColor] = useState(colorPalette[0]);

  useEffect(() => {
    // Update color based on the number of instruments
    setColor(colorPalette[instrumentCount % colorPalette.length]);
  }, [instrumentCount]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (instrumentType === 'option') {
      addInstrument({
        type: 'option',
        optionType,
        strikePrice: Number(strikePrice),
        position,
        maturityDate,
        color
      });
    } else {
      addInstrument({
        type: 'stock',
        position,
        shares: Number(shares),
        color
      });
    }
  };
  const renderTooltip = (title, content) => (
    <Tooltip title={content}>
      <IconButton size="small" sx={{ ml: 1 }}>
        <HelpOutlineIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} direction="column">
        {/* Risk-Free Rate section */}
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '1rem' }}>
            <Typography variant="h6" gutterBottom>
              Risk-Free Rate
              {renderTooltip("Risk-Free Rate", "The theoretical rate of return of an investment with zero risk, typically based on government securities.")}
            </Typography>
            <TextField
              fullWidth
              label="Risk-Free Rate (%)"
              type="number"
              value={riskFreeRate}
              onChange={(e) => setRiskFreeRate(Number(e.target.value))}
            />
          </Paper>
        </Grid>

        {/* Underlying Stock Parameters section */}
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '1rem' }}>
            <Typography variant="h6" gutterBottom>
              Underlying Stock Parameters
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Spot Price"
                  type="number"
                  value={spotPrice}
                  onChange={(e) => setSpotPrice(Number(e.target.value))}
                  InputProps={{
                    endAdornment: renderTooltip("Spot Price", "The current market price of the underlying stock.")
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Volatility (%)"
                  type="number"
                  value={volatility}
                  onChange={(e) => setVolatility(Number(e.target.value))}
                  InputProps={{
                    endAdornment: renderTooltip("Volatility", "A measure of the amount of fluctuation in the price of the underlying stock, typically expressed as a percentage.")
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Instrument Parameters section */}
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '1rem' }}>
            <Typography variant="h6" gutterBottom>Instrument Parameters</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="instrument-type-label">Instrument Type</InputLabel>
                  <Select
                    labelId="instrument-type-label"
                    value={instrumentType}
                    onChange={(e) => setInstrumentType(e.target.value)}
                    label="Instrument Type"
                  >
                    <MenuItem value="option">Option</MenuItem>
                    <MenuItem value="stock">Stock</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="position-label">Position</InputLabel>
                  <Select
                    labelId="position-label"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                    label="Position"
                    endAdornment={renderTooltip("Position", "Long: You buy the instrument. Short: You sell the instrument.")}
                  >
                    <MenuItem value="long">Long</MenuItem>
                    <MenuItem value="short">Short</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {instrumentType === 'option' && (
                <>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel id="option-type-label">Option Type</InputLabel>
                      <Select
                        labelId="option-type-label"
                        value={optionType}
                        onChange={(e) => setOptionType(e.target.value)}
                        label="Option Type"
                        endAdornment={renderTooltip("Option Type", "Call: Right to buy. Put: Right to sell.")}
                      >
                        <MenuItem value="call">Call</MenuItem>
                        <MenuItem value="put">Put</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Strike Price"
                      type="number"
                      value={strikePrice}
                      onChange={(e) => setStrikePrice(Number(e.target.value))}
                      InputProps={{
                        endAdornment: renderTooltip("Strike Price", "The price at which the option can be exercised.")
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Maturity Date"
                      type="date"
                      value={maturityDate}
                      onChange={(e) => setMaturityDate(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: renderTooltip("Maturity Date", "The date when the option expires.")
                      }}
                    />
                  </Grid>
                </>
              )}
              {instrumentType === 'stock' && (
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Number of Shares"
                    type="number"
                    value={shares}
                    onChange={(e) => setShares(Number(e.target.value))}
                    InputProps={{
                      endAdornment: renderTooltip("Number of Shares", "The quantity of stocks you want to buy or sell.")
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Color"
                  type="color"
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                  InputProps={{
                    endAdornment: renderTooltip("Color", "Choose a color to represent this instrument on the chart.")
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Add Instrument
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}


export default InstrumentForm;