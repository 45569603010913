import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button, TextField, Typography, Box, Checkbox, FormControlLabel } from '@mui/material';
import { strategies } from '../utils/strategies';

const StrategySelector = ({ addStrategy, spotPrice, hasExistingInstruments }) => {
  const [selectedStrategy, setSelectedStrategy] = useState('');
  const [customizations, setCustomizations] = useState({});
  const [keepExistingInstruments, setKeepExistingInstruments] = useState(true);

  const handleChange = (event) => {
    const strategyName = event.target.value;
    setSelectedStrategy(strategyName);
    if (strategyName) {
      setCustomizations(strategies[strategyName].defaultValues || {});
    } else {
      setCustomizations({});
    }
  };

  const handleCustomizationChange = (field, value) => {
    setCustomizations(prev => ({ ...prev, [field]: Number(value) }));
  };

  const handleAddStrategy = () => {
    if (selectedStrategy) {
      addStrategy(selectedStrategy, customizations, keepExistingInstruments);
      setSelectedStrategy('');
      setCustomizations({});
    }
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="strategy-select-label">Strategy</InputLabel>
        <Select
          labelId="strategy-select-label"
          id="strategy-select"
          value={selectedStrategy}
          label="Strategy"
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {Object.keys(strategies).map((strategy) => (
            <MenuItem key={strategy} value={strategy}>
              {strategy}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedStrategy && (
        <Box mt={2} p={2} border={1} borderColor="grey.300" borderRadius={1}>
          <Typography variant="h6">{selectedStrategy}</Typography>
          <Typography variant="body2" mb={2}>
            {strategies[selectedStrategy].description}
          </Typography>

          {Object.entries(customizations).map(([field, value]) => (
            <TextField
              key={field}
              label={field}
              value={value}
              onChange={(e) => handleCustomizationChange(field, e.target.value)}
              fullWidth
              margin="normal"
              type="number"
            />
          ))}

          {hasExistingInstruments && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={keepExistingInstruments}
                  onChange={(e) => setKeepExistingInstruments(e.target.checked)}
                />
              }
              label="Keep existing instruments"
            />
          )}

          <Button onClick={handleAddStrategy} variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
            Add Strategy
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default StrategySelector;