import React from 'react';
import { Typography, Paper, Box } from '@mui/material';

const Introduction = () => (
  <Paper elevation={3} sx={{ padding:  2}}>
    <Typography variant="body1">
      Welcome to OptionsStrategiesviz.com an interactive tool for visualizing options strategies. Use the form below to add individual options or stocks, or select a pre-defined strategy. The payoff chart will update in real-time to show you the potential outcomes of your strategy.
    </Typography>
  </Paper>
);

export default Introduction;