import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Typography, Tooltip as MuiTooltip, IconButton, Box } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { calculateGreeks } from '../utils/greekCalculations';

const VegaChart = ({ instruments, spotPrice, riskFreeRate, volatility }) => {
  const generateVegaData = () => {
    const minPrice = spotPrice * 0.5;
    const maxPrice = spotPrice * 1.5;
    const priceStep = (maxPrice - minPrice) / 100;

    return Array.from({ length: 101 }, (_, i) => {
      const currentPrice = minPrice + i * priceStep;
      const dataPoint = { price: currentPrice };

      instruments.forEach(instrument => {
        if (instrument.type === 'option') {
          let { vega } = calculateGreeks(instrument, currentPrice, riskFreeRate, volatility);
          if (instrument.position === 'short') {
            vega = -vega;
          }
          const key = `${instrument.optionType} ${instrument.strikePrice} (${instrument.position})`;
          dataPoint[key] = vega;
        }
      });

      return dataPoint;
    });
  };

  const data = generateVegaData();

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="h6" component="h2">Vega Chart</Typography>
        <MuiTooltip title="Vega measures the rate of change in the option's price with respect to the change in the underlying asset's implied volatility. Vega is highest for at-the-money options and decreases for in-the-money and out-of-the-money options. Vega is positive for long options and negative for short options.">
          <IconButton size="small">
            <HelpOutlineIcon fontSize="small" />
          </IconButton>
        </MuiTooltip>
      </Box>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="price" 
            type="number" 
            scale="linear"
            domain={['dataMin', 'dataMax']}
            label={{ value: 'Stock Price', position: 'insideBottom', offset: -10 }}
          />
          <YAxis 
            label={{ value: 'Vega', angle: -90, position: 'insideLeft' }}
          />
          <Tooltip />
          <Legend />
          {instruments
            .filter(instrument => instrument.type === 'option')
            .map((instrument, index) => {
              const key = `${instrument.optionType} ${instrument.strikePrice} (${instrument.position})`;
              const quantity = instrument.quantity || 1;
              return (
                <Line
                  key={index}
                  type="monotone"
                  dataKey={key}
                  name={`${key} (Qty: ${quantity})`}
                  stroke={instrument.color}
                  strokeWidth={2}
                  dot={false}
                />
              );
            })
          }
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default VegaChart;