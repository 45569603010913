import { addDays } from 'date-fns';

export const strategies = {
  bullCallSpread: {
    description: "A bullish strategy that involves buying a call option and selling another call option with a higher strike price.",
    defaultValues: {
      lowerStrike: 100,
      upperStrike: 110,
    },
    getInstruments: (spotPrice, customizations) => {
      const { lowerStrike, upperStrike } = customizations;
      const maturityDate = addDays(new Date(), 30).toISOString().split('T')[0];
      return [
        { type: 'option', optionType: 'call', position: 'long', strikePrice: lowerStrike, maturityDate, color: '#0000FF' },
        { type: 'option', optionType: 'call', position: 'short', strikePrice: upperStrike, maturityDate, color: '#FF0000' }
      ];
    },
  },
  bearPutSpread: {
    description: "A bearish strategy that involves buying a put option and selling another put option with a lower strike price.",
    defaultValues: {
      upperStrike: 100,
      lowerStrike: 90,
    },
    getInstruments: (spotPrice, customizations) => {
      const { upperStrike, lowerStrike } = customizations;
      const maturityDate = addDays(new Date(), 30).toISOString().split('T')[0];
      return [
        { type: 'option', optionType: 'put', position: 'long', strikePrice: upperStrike, maturityDate, color: '#0000FF' },
        { type: 'option', optionType: 'put', position: 'short', strikePrice: lowerStrike, maturityDate, color: '#FF0000' }
      ];
    },
  },
  ironCondor: {
    description: "A neutral strategy that involves a bull put spread and a bear call spread, profiting from low volatility.",
    defaultValues: {
      lowerPutStrike: 85,
      upperPutStrike: 95,
      lowerCallStrike: 105,
      upperCallStrike: 115,
    },
    getInstruments: (spotPrice, customizations) => {
      const { lowerPutStrike, upperPutStrike, lowerCallStrike, upperCallStrike } = customizations;
      const maturityDate = addDays(new Date(), 30).toISOString().split('T')[0];
      return [
        { type: 'option', optionType: 'put', position: 'short', strikePrice: lowerPutStrike, maturityDate, color: '#FF0000' },
        { type: 'option', optionType: 'put', position: 'long', strikePrice: upperPutStrike, maturityDate, color: '#0000FF' },
        { type: 'option', optionType: 'call', position: 'short', strikePrice: lowerCallStrike, maturityDate, color: '#FF0000' },
        { type: 'option', optionType: 'call', position: 'long', strikePrice: upperCallStrike, maturityDate, color: '#0000FF' }
      ];
    },
  },
  straddle: {
    description: "A neutral strategy that involves buying a call and a put with the same strike price, profiting from high volatility.",
    defaultValues: {
      strikePrice: 100,
    },
    getInstruments: (spotPrice, customizations) => {
      const { strikePrice } = customizations;
      const maturityDate = addDays(new Date(), 30).toISOString().split('T')[0];
      return [
        { type: 'option', optionType: 'call', position: 'long', strikePrice, maturityDate, color: '#0000FF' },
        { type: 'option', optionType: 'put', position: 'long', strikePrice, maturityDate, color: '#FF0000' }
      ];
    },
  },
  butterflyCallSpread: {
    description: "A neutral strategy that involves buying one call at a lower strike price, selling two calls at a middle strike price, and buying one call at a higher strike price.",
    defaultValues: {
      lowerStrike: 90,
      middleStrike: 100,
      upperStrike: 110,
    },
    getInstruments: (spotPrice, customizations) => {
      const { lowerStrike, middleStrike, upperStrike } = customizations;
      const maturityDate = addDays(new Date(), 30).toISOString().split('T')[0];
      return [
        { type: 'option', optionType: 'call', position: 'long', strikePrice: lowerStrike, maturityDate, color: '#0000FF' },
        { type: 'option', optionType: 'call', position: 'short', strikePrice: middleStrike, maturityDate, color: '#FF0000' },
        { type: 'option', optionType: 'call', position: 'short', strikePrice: middleStrike, maturityDate, color: '#FF0000' },
        { type: 'option', optionType: 'call', position: 'long', strikePrice: upperStrike, maturityDate, color: '#0000FF' }
      ];
    },
  },
  protectivePut: {
    description: "A hedging strategy that involves buying a stock and purchasing a put option to protect against downside risk.",
    defaultValues: {
      putStrike: 95,
      shares: 1,
    },
    getInstruments: (spotPrice, customizations) => {
      const { putStrike, shares } = customizations;
      const maturityDate = addDays(new Date(), 30).toISOString().split('T')[0];
      return [
        { type: 'stock', position: 'long', shares, color: '#0000FF' },
        { type: 'option', optionType: 'put', position: 'long', strikePrice: putStrike, maturityDate, color: '#FF0000' }
      ];
    },
  },
  coveredCall: {
    description: "An income-generating strategy that involves owning a stock and selling a call option against it.",
    defaultValues: {
      callStrike: 105,
      shares: 1,
    },
    getInstruments: (spotPrice, customizations) => {
      const { callStrike, shares } = customizations;
      const maturityDate = addDays(new Date(), 30).toISOString().split('T')[0];
      return [
        { type: 'stock', position: 'long', shares, color: '#0000FF' },
        { type: 'option', optionType: 'call', position: 'short', strikePrice: callStrike, maturityDate, color: '#FF0000' }
      ];
    },
  },
};

export const getStrategy = (strategyName, spotPrice, customizations) => {
  const strategy = strategies[strategyName];
  if (strategy && strategy.getInstruments) {
    return strategy.getInstruments(spotPrice, customizations);
  }
  return null;
};