import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Typography, Tooltip as MuiTooltip, IconButton, Box } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { calculateGreeks } from '../utils/greekCalculations';

const DeltaChart = ({ instruments, spotPrice, riskFreeRate, volatility }) => {
  const generateDeltaData = () => {
    const minPrice = spotPrice * 0.5;
    const maxPrice = spotPrice * 1.5;
    const priceStep = (maxPrice - minPrice) / 100;

    return Array.from({ length: 101 }, (_, i) => {
      const currentPrice = minPrice + i * priceStep;
      const dataPoint = { price: currentPrice };

      instruments.forEach(instrument => {
        if (instrument.type === 'option') {
          const { delta } = calculateGreeks(instrument, currentPrice, riskFreeRate, volatility);
          const adjustedDelta = instrument.position === 'short' ? -delta : delta;
          const key = `${instrument.optionType} ${instrument.strikePrice} (${instrument.position})`;
          dataPoint[key] = adjustedDelta;
        } else if (instrument.type === 'stock') {
          const stockDelta = instrument.position === 'long' ? 1 : -1;
          dataPoint['stock'] = stockDelta;
        }
      });

      return dataPoint;
    });
  };

  const data = generateDeltaData();

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="h5" component="h2">Delta Chart</Typography>
        <MuiTooltip title="Delta measures the rate of change in the option's price with respect to the change in the underlying asset's price. It ranges from -1 to 1 for options, with calls having positive delta and puts having negative delta.">
          <IconButton size="small">
            <HelpOutlineIcon fontSize="small" />
          </IconButton>
        </MuiTooltip>
      </Box>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="price" 
            type="number" 
            scale="linear"
            domain={['dataMin', 'dataMax']}
            label={{ value: 'Stock Price', position: 'insideBottom', offset: -10 }}
          />
          <YAxis 
            label={{ value: 'Delta', angle: -90, position: 'insideLeft' }}
            domain={[-1, 1]}
            ticks={[-1, -0.5, 0, 0.5, 1]}
          />
          <Tooltip />
          <Legend />
          {instruments.map((instrument, index) => {
            const key = instrument.type === 'option' 
              ? `${instrument.optionType} ${instrument.strikePrice} (${instrument.position})`
              : 'stock';
            const quantity = instrument.type === 'option' ? instrument.quantity || 1 : instrument.shares || 1;
            return (
              <Line
                key={index}
                type="monotone"
                dataKey={key}
                name={`${key} (Qty: ${quantity})`}
                stroke={instrument.color}
                strokeWidth={2}
                dot={false}
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default DeltaChart;