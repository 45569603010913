import { differenceInDays } from 'date-fns';



export function calculatePremium(optionType, strikePrice, spotPrice, maturityDate, riskFreeRate, volatility) {

    const r = riskFreeRate / 100;

    const sigma = volatility / 100;

    

    const now = new Date();

    const maturity = new Date(maturityDate);

    const timeToMaturity = differenceInDays(maturity, now) / 365; // Time in years



    if (timeToMaturity <= 0) {

        return 0;

    }



    const d1 = (Math.log(spotPrice / strikePrice) + (r + 0.5 * Math.pow(sigma, 2)) * timeToMaturity) / (sigma * Math.sqrt(timeToMaturity));

    const d2 = d1 - sigma * Math.sqrt(timeToMaturity);



    const N = (x) => (1.0 + mathErf(x / Math.sqrt(2.0))) / 2.0;



    let premium;

    if (optionType === 'call') {

        premium = spotPrice * N(d1) - strikePrice * Math.exp(-r * timeToMaturity) * N(d2);

    } else if (optionType === 'put') {

        premium = strikePrice * Math.exp(-r * timeToMaturity) * N(-d2) - spotPrice * N(-d1);

    }



    return isNaN(premium) || premium < 0 ? 0 : premium;

}



function mathErf(x) {

    // Constants

    const a1 =  0.254829592;

    const a2 = -0.284496736;

    const a3 =  1.421413741;

    const a4 = -1.453152027;

    const a5 =  1.061405429;

    const p  =  0.3275911;



    // Save the sign of x

    const sign = (x >= 0) ? 1 : -1;

    x = Math.abs(x);



    // A&S formula 7.1.26

    const t = 1.0/(1.0 + p*x);

    const y = 1.0 - (((((a5*t + a4)*t) + a3)*t + a2)*t + a1)*t*Math.exp(-x*x);



    return sign*y;

}



export function calculateOptionPayoff(option, currentSpotPrice) {

    const { optionType, position, strikePrice, premium } = option;

    let intrinsicValue = optionType === 'call' 

        ? Math.max(currentSpotPrice - strikePrice, 0)

        : Math.max(strikePrice - currentSpotPrice, 0);

    

    let payoff = intrinsicValue - premium;

    return position === 'short' ? -payoff : payoff;

}



export function calculateStockPayoff(stock, currentSpotPrice, initialSpotPrice) {

    const { position, shares = 1 } = stock;

    let payoff = (currentSpotPrice - initialSpotPrice) * shares;

    return position === 'short' ? -payoff : payoff;

}



export function calculateIndividualPayoff(instrument, spotPrice, riskFreeRate, volatility, range = 50) {

    const minSpotPrice = spotPrice - range;

    const maxSpotPrice = spotPrice + range;

    const spotPrices = Array.from({ length: 101 }, (_, i) => minSpotPrice + (i / 100) * (maxSpotPrice - minSpotPrice));



    return spotPrices.map(currentSpotPrice => ({

        spotPrice: currentSpotPrice,

        payoff: instrument.type === 'option'

            ? calculateOptionPayoff(instrument, currentSpotPrice)

            : calculateStockPayoff(instrument, currentSpotPrice, spotPrice)

    }));

}



export function calculateTotalPayoff(instruments, spotPrice, riskFreeRate, volatility, range = 50) {

    const minSpotPrice = spotPrice - range;

    const maxSpotPrice = spotPrice + range;

    const spotPrices = Array.from({ length: 101 }, (_, i) => minSpotPrice + (i / 100) * (maxSpotPrice - minSpotPrice));



    return spotPrices.map(currentSpotPrice => ({

        spotPrice: currentSpotPrice,

        payoff: instruments.reduce((total, instrument) => {

            if (instrument.type === 'option') {

                return total + calculateOptionPayoff(instrument, currentSpotPrice);

            } else if (instrument.type === 'stock') {

                return total + calculateStockPayoff(instrument, currentSpotPrice, spotPrice);

            }

            return total;

        }, 0)

    }));

}



export function calculateInstrumentValue(instrument, spotPrice, riskFreeRate, volatility) {

    if (instrument.type === 'option') {

        return calculatePremium(

            instrument.optionType,

            instrument.strikePrice,

            spotPrice,

            instrument.maturityDate,

            riskFreeRate,

            volatility

        );

    } else if (instrument.type === 'stock') {

        return instrument.shares * spotPrice;

    }

    return 0;

}
