import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField, Select, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { calculateGreeks } from '../utils/greekCalculations';

const InstrumentTable = ({ instruments, removeInstrument, updateInstrument, spotPrice, riskFreeRate, volatility }) => {
  const [editingCell, setEditingCell] = useState({ row: null, field: null });
  const [editValue, setEditValue] = useState('');



  const handleDoubleClick = (index, field, value) => {

    if (field !== 'premium' && field !== 'volatility') {

      setEditingCell({ row: index, field });

      setEditValue(value);

    }

  };



  const handleChange = (e) => {

    setEditValue(e.target.value);

  };



  const handleBlur = () => {

    if (editingCell.row !== null && editingCell.field !== null) {

      const updatedInstrument = {

        ...instruments[editingCell.row],

        [editingCell.field]: editValue

      };

      updateInstrument(editingCell.row, updatedInstrument);

      setEditingCell({ row: null, field: null });

    }

  };



  const renderCell = (instrument, index, field) => {

    const isEditing = editingCell.row === index && editingCell.field === field;

    const value = instrument[field];



    if (isEditing) {

      switch (field) {

        case 'position':

          return (

            <Select

              value={editValue}

              onChange={handleChange}

              onBlur={handleBlur}

              autoFocus

            >

              <MenuItem value="long">Long</MenuItem>

              <MenuItem value="short">Short</MenuItem>

            </Select>

          );

        case 'color':

          return (

            <TextField

              type="color"

              value={editValue}

              onChange={handleChange}

              onBlur={handleBlur}

              autoFocus

            />

          );

        default:

          return (

            <TextField

              type={field === 'maturityDate' ? 'date' : 'number'}

              value={editValue}

              onChange={handleChange}

              onBlur={handleBlur}

              autoFocus

            />

          );

      }

    } else {

      if (field === 'color') {

        return (

          <div

            onDoubleClick={() => handleDoubleClick(index, field, value)}

            style={{

              width: '20px',

              height: '20px',

              backgroundColor: value,

              border: '1px solid #000',

              cursor: 'pointer'

            }}

          />

        );

      } else {

        return (

          <span onDoubleClick={() => handleDoubleClick(index, field, value)}>

            {field === 'premium' ? value.toFixed(2) : value}

          </span>

        );

      }

    }

  };

  
  const renderGreeks = (instrument) => {
    if (instrument.type === 'option') {
      const greeks = calculateGreeks(instrument, spotPrice, riskFreeRate, volatility);
      return (
        <>
          <TableCell>{greeks.delta.toFixed(4)}</TableCell>
          <TableCell>{greeks.gamma.toFixed(4)}</TableCell>
          <TableCell>{greeks.theta.toFixed(4)}</TableCell>
          <TableCell>{greeks.vega.toFixed(4)}</TableCell>
        </>
      );
    } else {
      return (
        <>
          <TableCell>N/A</TableCell>
          <TableCell>N/A</TableCell>
          <TableCell>N/A</TableCell>
          <TableCell>N/A</TableCell>
        </>
      );
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Position</TableCell>
            <TableCell>Strike</TableCell>
            <TableCell>Maturity</TableCell>
            <TableCell>Premium</TableCell>
            <TableCell>Volatility</TableCell>
            <TableCell>Color</TableCell>
            <TableCell>Delta</TableCell>
            <TableCell>Gamma</TableCell>
            <TableCell>Theta</TableCell>
            <TableCell>Vega</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {instruments.map((instrument, index) => (
            <TableRow key={index}>
              <TableCell>
                {instrument.type === 'option' ? instrument.optionType : 'Stock'}
              </TableCell>
              <TableCell>{renderCell(instrument, index, 'position')}</TableCell>
              <TableCell>
                {instrument.type === 'option' ? renderCell(instrument, index, 'strikePrice') : 'N/A'}
              </TableCell>
              <TableCell>
                {instrument.type === 'option' ? renderCell(instrument, index, 'maturityDate') : 'N/A'}
              </TableCell>
              <TableCell>
                {instrument.type === 'option' ? instrument.premium.toFixed(2) : 'N/A'}
              </TableCell>
              <TableCell>{volatility}%</TableCell>
              <TableCell>{renderCell(instrument, index, 'color')}</TableCell>
              {renderGreeks(instrument)}
              <TableCell>
                <IconButton onClick={() => removeInstrument(index)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InstrumentTable;