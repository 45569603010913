import React from 'react';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TimelineIcon from '@mui/icons-material/Timeline';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const ChartSelector = ({ selectedCharts, handleChartSelection }) => {
  return (
    <ToggleButtonGroup
      value={selectedCharts}
      onChange={handleChartSelection}
      aria-label="chart selection"
    >
      <ToggleButton value="payoff" aria-label="payoff chart">
        <ShowChartIcon />
        Payoff
      </ToggleButton>
      <ToggleButton value="delta" aria-label="delta chart">
        <TimelineIcon />
        Delta
      </ToggleButton>
      <ToggleButton value="gamma" aria-label="gamma chart">
        <TrendingUpIcon />
        Gamma
      </ToggleButton>
      <ToggleButton value="vega" aria-label="vega chart">
        <ShowChartIcon />
        Vega
      </ToggleButton>
      <ToggleButton value="theta" aria-label="theta chart">
        <ShowChartIcon />
        Theta
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ChartSelector;