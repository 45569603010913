import { differenceInDays } from 'date-fns';

// Standard normal cumulative distribution function
function normalCDF(x) {
  const t = 1 / (1 + 0.2316419 * Math.abs(x));
  const d = 0.3989423 * Math.exp(-x * x / 2);
  const p = d * t * (0.3193815 + t * (-0.3565638 + t * (1.781478 + t * (-1.821256 + t * 1.330274))));
  return x > 0 ? 1 - p : p;
}

// Standard normal probability density function
function normalPDF(x) {
  return Math.exp(-0.5 * x * x) / Math.sqrt(2 * Math.PI);
}

export function calculateGreeks(option, spotPrice, riskFreeRate, volatility, providedTimeToMaturity = null) {
  const { optionType, strikePrice, maturityDate } = option;
  const r = riskFreeRate / 100;
  const sigma = volatility / 100;

  let T;
  if (providedTimeToMaturity !== null) {
    T = providedTimeToMaturity;
  } else {
    const now = new Date();
    const maturity = new Date(maturityDate);
    T = Math.max(0, differenceInDays(maturity, now) / 365); // Time to maturity in years, minimum 0
  }

  // Handle expiration or very close to expiration
  if (T < 1 / 365) { // Less than 1 day to expiration
    return { delta: 0, gamma: 0, theta: 0, vega: 0 };
  }

  const d1 = (Math.log(spotPrice / strikePrice) + (r + 0.5 * sigma * sigma) * T) / (sigma * Math.sqrt(T));
  const d2 = d1 - sigma * Math.sqrt(T);

  let delta, gamma, theta, vega;

  if (optionType === 'call') {
    delta = normalCDF(d1);
    theta = (-spotPrice * normalPDF(d1) * sigma / (2 * Math.sqrt(T)) 
             - r * strikePrice * Math.exp(-r * T) * normalCDF(d2)) / 365;
  } else { // put
    delta = normalCDF(d1) - 1;
    theta = (-spotPrice * normalPDF(d1) * sigma / (2 * Math.sqrt(T)) 
             + r * strikePrice * Math.exp(-r * T) * normalCDF(-d2)) / 365;
  }

  gamma = normalPDF(d1) / (spotPrice * sigma * Math.sqrt(T));
  vega = spotPrice * Math.sqrt(T) * normalPDF(d1) / 100;

  return { delta, gamma, theta, vega };
}